<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <BaseDateSelect
              :value="editedItem.date_signature"
              label="Date de signature"
              :refs="refs"
              @input="(data) => {update_ei({'field': 'date_signature', 'value': data})}"
          />
        </v-col>
        <v-col cols="6">
          <BaseDateSelect
              :value="editedItem.date_fin"
              label="Date de fin"
              :refs="refs"
              @input="(data) => {update_ei({'field': 'date_fin', 'value': data})}"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="pl-0 py-0">
          <v-text-field type="number" :value="editedItem.loyer"
                        @input="update_ei({'field': 'loyer', 'value': $event})"
                        label="Loyer" cols="6"></v-text-field>
        </v-col>
        <v-col cols="6" class="pr-0 py-0">
          <BaseUnitSelect
              :value="editedItem.unite_loyer"
              label="Unités"
              @input="(data) => {update_ei({'field': 'unite_loyer', 'value': data})}"
          ></BaseUnitSelect>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {format_functions} from "@/mixins/UtilityMixin";
import CPMSService from "@/services/CPMSService";
import {mapActions, mapState} from "vuex";

export default {
  name: "DefautContrat",
  mixins: [format_functions],
  props: ['refs'],
  data() {
    return {
      defaultItem: {'date_signature': null, 'date_fin': null, 'loyer': 400, 'unite_loyer': '€/Eol/mois'},
    }
  },
  created() {
    if (Object.keys(this.editedItem).length === 0) {
      this.init_ei(this.defaultItem)
    }
  },
  methods: {
    ...mapActions('edition', [
      'init_ei',
      'update_ei'
    ]),
  },
  computed: {
    ...mapState({
      editedItem: state => state.edition.editedItem
    }),
  },
}
</script>

<style scoped>

</style>