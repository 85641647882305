<template>
  <div>
    <div>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Date de signature : {{ ISOtoUSR(c.date_signature) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Date de fin : {{ ISOtoUSR(c.date_fin) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Loyer : {{ c.loyer }} {{ c.unite_loyer }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import {format_functions} from "@/mixins/UtilityMixin";

export default {
  name: "MatDeMeusre",
  props: ['c'],
  mixins: [format_functions],

}
</script>

<style scoped>

</style>